<template>
  <div class="tc_measure_chart">
    <!-- 筛选 -->
    <div class="filtrate">
      <div class="header-title">
        <p>筛选</p>
      </div>
      <div class="header-from">
        <el-form
            :inline="true"
            :model="formInline"
            class="demo-form-inline"
            size="mini"
            ref="formInline"
        >
          <el-form-item label="测评量表" prop="id">
            <el-select v-model="formInline.id">
              <el-option
                  :label="item.measure_title"
                  :value="item.id"
                  v-for="(item, key) in scaleList"
                  :key="key"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="科室部门" prop="department_id">
            <UnlimateSelect :item-data="departmentList" @input="formInline.department_id = $event[0]" />
          </el-form-item>
          <el-form-item label="年龄范围" prop="date">
            <div class="twoData">
              <el-date-picker
                  v-model="formInline.stime"
                  type="date"
                  placeholder="选择日期"
                  value-format="timestamp"
              >
              </el-date-picker>
              至
              <!-- 结束 -->
              <el-date-picker
                  v-model="formInline.etime"
                  type="date"
                  placeholder="选择日期"
                  value-format="timestamp"
              >
              </el-date-picker>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button plain @click="getchartData">查询</el-button>
            <el-button @click="resetForm('formInline')">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 图表 -->
    <div class="measure_chart">
      <div class="rowpage rowpage1">
        <div class="row1">
<!--          <div class="col1">-->
<!--            <div-->
<!--                id="chart-melancholy-data"-->
<!--                :style="{ width: '100%', height: '100%' }"-->
<!--            ></div>-->
<!--          </div>-->
<!--          <div class="col2">-->
<!--            <div-->
<!--                id="chart-userSexData-data"-->
<!--                :style="{ width: '100%', height: '100%' }"-->
<!--            ></div>-->
<!--          </div>-->
          <div class="col1">
            <div
                id="chart-Slninety-data"
                :style="{ width: '100%', height: '100%' }"
            ></div>
            <el-button type="small" style="position: absolute; top: 0;right: 0;" @click="exportExcelofBlob('factor')">导出</el-button>
          </div>
          <div class="col2">
            <div
                id="chart-Slninety-percent"
                :style="{ width: '100%', height: '100%' }"
            ></div>
            <el-button type="small" style="position: absolute; top: 0;right: 0;" @click="exportExcelofBlob('yang')">导出</el-button>
          </div>
        </div>
        <div class="row2">
          <div class="col1">
            <div
                id="chart-Slninety-all"
                :style="{ width: '100%', height: '100%' }"
            ></div>
            <el-button type="small" style="position: absolute; top: 0;right: 0;" @click="exportExcelofBlob('total_score')">导出</el-button>
          </div>
          <div class="col2"></div>
        </div>
      </div>
      <!-- <div class="rowpage rowpage2">
        <div class="row1">
          <div class="col1">
            <div></div>
          </div>
          <div class="col2">
          </div>
        </div>
        <div class="row2">
          <div class="col1">
            <div></div>
          </div>
          <div class="col2"></div>
        </div>
      </div> -->
    </div>
    <!-- 搜索模态框 -->
    <el-dialog
      title="选择量表"
      :visible.sync="search"
      width="30%"
      center
      :close-on-click-modal="false"
    >
    <el-form :model="formInline" label-width="80px">
      <el-form-item label="测评量表">
        <el-select v-model="formInline.id" placeholder="必须选择">
          <el-option
              :label="item.measure_title"
              :value="item.id"
              v-for="(item, key) in scaleList"
              :key="key"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="getchartData()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { loadArchivesInfo } from "@/api/evaluate.js";
import { loadDepartmentData } from "@/api/comm.js";
import { getMeasureStatistics } from "@/api/user_info_statistical.js";
export default {
  data() {
    return {
      formInline: {
        date: [],
        id: "",
        stime: "",
        etime: "",
      },
      sex: "",
      age: "",
      search: true,
      occupation: [
        "医生学生",
        "护士",
        "行政人员",
        "  志愿者",
        "  医学生",
        "培训医师",
        "警察",
        "军人",
        "退休",
        "零时工/个体",
        "家庭妇女",
        "社区工作人员",
        "国有企业员工",
        "私营企业员工",
        "后勤人员",
        "企事业单位职员",
        "公务员",
        "教师",
        "专业技术人员",
        "工人",
        "农民",
        "自由职业者",
        "商业/服务业人员",
        "无业/待业",
        "其他",
      ],
      scaleData: [],
      scaleList: [],
      // 部门
      departmentList: [],
      // 图表数据
      charData: {
        list: [
          {
            value: 285,
            name: "黑名单查询",
          },
          {
            value: 410,
            name: "红名单查询",
          },
          {
            value: 274,
            name: "法人行政处罚",
          },
          {
            value: 235,
            name: "其它查询",
          },
        ],
      },
    };
  },
  created() {
    // 获取量表列表
    this.loadScaleData();
    // 获取所有部门 done
    loadDepartmentData().then((res) => {
      this.departmentList = res.data.data;
    });
  },
  mounted() {
    setTimeout(() => {
      // this.chartmelancholyData();
      // this.charUserSexData();
    }, 1000);
  },
  methods: {
    // 重置
    resetForm(formName) {
      this.$nextTick(() => {
        this.$refs[formName].resetFields();
      });
    },
    // 图1
    chartmelancholyData(res, leng, data) {
      let melancholyData = this.$echarts.init(
          document.getElementById("chart-melancholy-data")
      );
      melancholyData.setOption({
        backgroundColor: "#ffffff",
        title: {
          text: res.title,
          left: "center",
          top: 10,
          textStyle: {},
        },

        tooltip: {
          trigger: "item",
          formatter: "{b} : {c} ({d}%)",
        },

        visualMap: {
          show: false,
          min: 500,
          max: 600,
          inRange: {
            //colorLightness: [0, 1]
          },
        },
        legend: {
          itemWidth: 14,
          itemHeight: 5,
          data: leng,
          x: "70%",
          y: "10%",
        },
        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: "50%",
            center: ["50%", "60%"],
            radius: ["0", "65%"],
            color: ["rgb(131,249,103)", "#FBFE27", "#FE5050", "#1DB7E5"], //'#FBFE27','rgb(11,228,96)','#FE5050'
            data: data.sort(function (a, b) {
              return a.value - b.value;
            }),
            roseType: "radius",

            label: {
              normal: {
                formatter: ["{c|{c}次}", "{b|{b}}"].join("\n"),
                rich: {
                  c: {
                    color: "rgb(0,0,0)",
                    fontSize: 15,
                    fontWeight: "bold",
                    lineHeight: 5,
                  },
                  b: {
                    color: "rgb(98,137,169)",
                    fontSize: 10,
                    height: 40,
                  },
                },
              },
            },
            labelLine: {
              normal: {
                lineStyle: {
                  color: "rgb(98,137,169)",
                },
                smooth: 0.2,
                length: 10,
                length2: 20,
              },
            },
            itemStyle: {
              normal: {},
            },
          },
        ],
      });
    },
    // 图2
    charUserSexData(res) {
      let userSexData = this.$echarts.init(
          document.getElementById("chart-userSexData-data")
      );

      userSexData.setOption({
        backgroundColor: "transparent",
        title: {
          text: res.title,
          textStyle: {
            fontSize: 20,
          },
          x: "center",
          y: "10px",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            lineStyle: {
              color: "#57617B",
            },
          },
        },
        // legend: {
        //   icon: "rect",
        //   itemWidth: 14,
        //   itemHeight: 5,
        //   itemGap: 13,
        //   data: ["男", "女"],
        //   right: "4%",
        //   x: "80%",
        //   y: "10%",
        //   textStyle: {
        //     fontSize: 12,
        //     color: "black"
        //   }
        // },
        grid: {
          top: "20%",
          left: "3%",
          right: "5%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisLine: {
              lineStyle: {
                color: "#57617B",
              },
            },
            data: res.user.factor,
          },
          {
            axisPointer: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "#57617B",
              },
            },
            axisTick: {
              show: false,
            },

            position: "bottom",
            offset: 20,
            data: [
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              // {
              //   value: "月收入/万元",
              //   textStyle: {
              //     fontSize: 13,
              //     align: "left"
              //   }
              // }
            ],
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "单位（分）",
            axisTick: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "#57617B",
              },
            },
            axisLabel: {
              margin: 10,
              textStyle: {
                fontSize: 14,
              },
            },
            splitLine: {
              lineStyle: {
                color: "#57617B",
              },
            },
          },
        ],
        series: [
          {
            // name: "男",
            type: "line",
            smooth: true,
            symbol: "circle",
            symbolSize: 5,
            showSymbol: false,
            lineStyle: {
              normal: {
                width: 1,
              },
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [
                      {
                        offset: 0,
                        color: "rgba(18,162,252, 0.3)",
                      },
                      {
                        offset: 0.8,
                        color: "rgba(18,162,252, 0)",
                      },
                    ],
                    false
                ),
                shadowColor: "rgba(0, 0, 0, 0.1)",
                shadowBlur: 10,
              },
            },
            itemStyle: {
              normal: {
                color: "rgb(18,162,252)",
                borderColor: "rgba(18,162,252,0.3)",
                borderWidth: 12,
              },
            },
            data: res.user.man,
          },
          {
            // name: "女",
            type: "line",
            smooth: true,
            symbol: "circle",
            symbolSize: 5,
            showSymbol: false,
            lineStyle: {
              normal: {
                width: 1,
              },
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [
                      {
                        offset: 0,
                        color: "rgba(240,72,134, 0.3)",
                      },
                      {
                        offset: 0.8,
                        color: "rgba(240,72,134, 0)",
                      },
                    ],
                    false
                ),
                shadowColor: "rgba(0, 0, 0, 0.1)",
                shadowBlur: 10,
              },
            },
            itemStyle: {
              normal: {
                color: "rgb(240,72,134)",
                borderColor: "rgba(240,72,134,0.5)",
                borderWidth: 12,
              },
            },
            data: res.user.woman,
          },
        ],
      });
    },
    // 量表数据请求
    async loadScaleData() {
      var res = await axios.get("/all_measure_classify");
      if (res.data.code === 400200) {
        this.scaleData = res.data.data;
        res.data.data.forEach((v) => {
          v.status_measure.forEach((v) => {
            this.scaleList.push(v);
          });
        });
      } else {
        return;
      }
    },
    // 查询请求 ---- 目前选择了但是还没有出现图表 只要出现图表就行了
    async getchartData(id = null) {
      // console.log(this.formInline);
      if (this.formInline.id == "") {
        this.$message({
          showClose: true,
          message: "请选择量表后，在查询",
          type: "warning",
        });
        return false;
      }
      this.search = false;
      this.insearchData = JSON.parse(JSON.stringify(this.formInline));
      this.insearchData.stime = this.insearchData.stime / 1000;
      this.insearchData.etime = this.insearchData.etime / 1000;
      delete this.insearchData.date;
      if(id) {
        this.insearchData.parent_id = id
      }
      console.log(this.insearchData)
      getMeasureStatistics(this.insearchData).then((res) => {
        console.log(res.data.data);
        let data = res.data.data;
        // if (data.factor) {
        this.SCLninetyStatistics(data.factor, this.insearchData);
        // }
        // if(data.yang) {
        this.SCLninetyPercent(data.yang, this.insearchData)
        // }
        // if(data.total_score){
        this.SCLninetyAll(data.total_score, this.insearchData)
        // }
        // if (res.code != 40040 && res.data.measure) {
        //   // 数据处理
        //   // 1-图示
        //   let leng = [];
        //   res.data.measure.forEach((v) => {
        //     leng.push(v.name);
        //   });
        //   // 2-数据
        //   let data = res.data.measure.filter((v) => {
        //     return v.value != 0;
        //   });
        //   // this.chartmelancholyData(res.data, leng, data);
        //   // this.charUserSexData(res.data);
        // }
        // this.charData = res.data;

        // this.SCLninetyPercent(res.data);
      });
    },
    // 因子图表
    async SCLninetyStatistics(data,searchData) {
      var chartDom = document.getElementById("chart-Slninety-data");
      var myChart = this.$echarts.init(chartDom);
      var option;

      if(data.length == 0) {
        myChart.clear()
        return
      }

      let dataList = [];
      let dataTitle = [];
      let titleText = ''
      var emphasisStyle = {
        itemStyle: {
          shadowBlur: 10,
          shadowColor: "rgba(0,0,0,0.3)",
        },
      };

      // console.log(searchData)
      if(!searchData.parent_id) {
        titleText = '各医院各因子阳性人数占比'
      } else {
        this.parents.forEach(item => {
          if(item.id == searchData.parent_id) {
            titleText = item.name + '各科室各因子阳性人数占比'
          }
        })
      }

      data.title = Object.values(data.title);
      let i = 0;
      data.title.forEach((title,k)=>{
        dataList[k] = { name: title ,type: 'bar', emphasis: emphasisStyle, data:[], label: { show: true, position: 'top' } };
        for(let tag_title in data.cal){
          if (dataList[k].data[i] === undefined) {
            dataList[k].data[i] = [];
          }
          dataList[k].data[i] = data.cal[tag_title][k]['count'];
          i++;
        }
        i = 0;
      });


      // console.log(data)


      // return;
      // for(let k in data.cal){
      //   tempList[k] = [];
      //   data.cal[k].forEach((v,key)=>{
      //     // console.log({key,v});
      //     for (let index in data.title) {
      //       let title =  data.title[index];
      //       if ( tempList[k][title] === undefined) {
      //         tempList[k][title] = {};
      //       }

      //       tempList[k][title] = v;
      //     }
      //     })
      // }
      // console.log({tempList});
      // return;
      // for(let title in data.title){
      //     if (tempList[title] === undefined) {
      //       tempList[title] = [];
      //     }
      //     data.cal[k].forEach((v,key)=>{
      //       if (tempList[title][k] === undefined) {
      //         tempList[title][k] = [];
      //       }
      //       tempList[title][k][key] = v;
      //     })
      //   }
      // console.log({tempList});
      // return;
      for(let j in data.title) {
        dataTitle.push(data.title[j])
        // dataList.push({ name: data.title[j], type: 'bar', emphasis: emphasisStyle, data: [] })
      }
      // for(let i in data.cal) {
      //   dataList.push({ name: i, type: 'bar', emphasis: emphasisStyle, data: data.cal[i].map(item => item.count) })
      // }

      option = {
        title: {
          text: titleText,
          left: "center",
        },
        legend: {
          data: dataTitle,
          top: "10%",
        },
        tooltip: {},
        xAxis: {
          data: data.tag_name,
          alignTicks: true,
          // axisLine: { onZero: true },
          // splitLine: { show: false },
          // splitArea: { show: false },
        },
        yAxis: {},
        grid: {
          top: 100,
          bottom: 70,
          right: 10,
          left: 40,
        },
        dataZoom: [
          {
            show: true,
            start: 0,
            end: 100
          }
        ],
        series: dataList
      };

      option && myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    // 阳图表
    async SCLninetyPercent(data, searchData) {
      // console.log(data);
      // data = data.cal['阳性'].map((item,index) => {
      //   return { name : data.title[index], value: item.count }
      // })
      var chartDom = document.getElementById("chart-Slninety-percent");
      var myChart1 = this.$echarts.init(chartDom);
      var option;

      if(data.length == 0) {
        myChart1.clear()
        return
      }
      let titleText = '';
      if(!searchData.parent_id) {
        titleText = '各医院阳性人数占比'
      } else {
        this.parents.forEach(item => {
          if(item.id == searchData.parent_id) {
            titleText = item.name + '各科室阳性人数占比'
          }
        })
      }

      option = {
        title: {
          text: titleText,
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          // orient: "vertical",
          top: "bottom",
          // right: 'center'
        },
        series: [
          {
            type: "pie",
            radius: "50%",
            data: data.cal['阳性'].map((item,index) => {
              return { name : data.title[index], value: item.count }
            }),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            }
          },
        ],
      };

      option && myChart1.setOption(option);

      window.addEventListener("resize", function () {
        myChart1.resize();
      });
    },
    // 医院统计表
    async SCLninetyAll(data, searchData) {

      var chartDom = document.getElementById("chart-Slninety-all");
      var myChart = this.$echarts.init(chartDom);
      var option;

      if(data.length == 0) {
        myChart.clear()
        return
      }
      let titleText = ''
      let dataList = [];
      let dataTitle = [];
      var emphasisStyle = {
        itemStyle: {
          shadowBlur: 10,
          shadowColor: "rgba(0,0,0,0.3)",
        },
      };


      if(!searchData.parent_id) {
        titleText = '各医院人员心理健康状况统计'
      } else {
        this.parents.forEach(item => {
          if(item.id == searchData.parent_id) {
            titleText = item.name + '各科室人员心理健康状况统计'
          }
        })
      }


      // let dataList = [];
      data.title = Object.values(data.title);
      let i = 0;
      data.title.forEach((title,k)=>{
        dataList[k] = { name: title ,type: 'bar', emphasis: emphasisStyle, data:[], label: { show: true, position: 'top' } };
        for(let tag_title in data.cal){
          if (dataList[k].data[i] === undefined) {
            dataList[k].data[i] = [];
          }
          // dataList[k].data[i] = {name:tag_title,value:data.cal[tag_title][k]['count']};
          dataList[k].data[i] = data.cal[tag_title][k]['count'];
          i++;
        }
        i = 0;
      });

      for(let j in data.title) {
        dataTitle.push(data.title[j])
        // dataList.push({ name: data.title[j], type: 'bar', emphasis: emphasisStyle, data: [] })
      }

      option = {
        title: {
          text: titleText,
          left: "center",
        },
        legend: {
          data: dataTitle,
          top: "10%",
        },
        tooltip: {},
        xAxis: {
          data: data.tag_name,
          axisLine: { onZero: true },
          splitLine: { show: false },
          splitArea: { show: false },
        },
        yAxis: {},
        grid: {
          top: 100,
          bottom: 70,
          right: 10,
          left: 40
        },
        dataZoom: [
          {
            show: true,
            start: 0,
            end: 100
          },
          {
            type: 'inside',
            start: 94,
            end: 100
          },
        ],
        series: dataList
      };

      option && myChart.setOption(option);
      window.addEventListener("resize", function () {
        // console.log(1);
        myChart.resize();
      });
    },
  },
};
</script>

<style lang="less">
.tc_measure_chart {
  width: 100%;
  height: calc(~"100% - 126px");
  padding-bottom: 10px;
  // 筛选
  .filtrate {
    display: flex;
    width: 100%;
    height: 50px;
    background: rgb(217, 242, 247);
    margin-top: 20px;
    .header-title {
      color: rgb(4, 87, 102);
      width: 56px;
      text-align: center;
      border-right: 1px solid rgb(127, 222, 243);
    }
    p {
      line-height: 50px;
      font-size: 14px;
    }
    .header-from {
      padding-left: 10px;
      display: flex;
      align-items: center;
      .el-input__inner {
        width: 120px;
        padding-right: 20px;
      }
      .el-form-item:nth-child(3) .el-input__inner {
        width: 230px;
        padding-right: 0;
      }
      .el-form-item {
        margin-bottom: 0;
      }
      .el-form-item__label {
        color: rgb(4, 87, 102);
      }
      .el-input__icon {
        width: 15px;
      }
      .el-button--mini {
        padding: 5px 10px;
        background: transparent;
        color: rgb(4, 87, 102);
        font-size: 16px;
        &:hover {
          border: 1px solid rgb(105, 105, 105);
        }
      }
    }
  }
  // 图表
  .measure_chart {
    margin-top: 20px;
    width: 100%;
    height: calc(~"100% - 50px");
    display: flex;
    flex-direction: column;
    box-sizing: content-box;
    .rowpage {
      width: 100%;
      height: 100%;
      .row1 {
        flex: 1;
        height: 50%;
        margin-bottom: 10px;
        display: flex;
        .col1 {
          flex: 1;
          background: #ffff;
          margin-right: 10px;
          border: 1px #b9b9b9 solid;
          position: relative;
        }
        .col2 {
          flex: 1;
          background: #ffff;
          border: 1px #b9b9b9 solid;
          position: relative;
        }
      }
      .row2 {
        flex: 1;
        height: 50%;
        display: flex;
        .col1 {
          flex: 1;
          background: #ffff;
          margin-right: 10px;
          border: 1px #b9b9b9 solid;
          position: relative;
        }
        .col2 {
          flex: 1;
          background: #ffff;
          border: 1px #b9b9b9 solid;
          position: relative;
        }
      }
    }
  }
  .twoData {
    display: flex;
    .el-date-editor {
      width: unset;
      .el-input__inner {
        width: 140px !important;
      }
    }
  }
}
</style>
